// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout } from '@/layouts'
import storage from 'store'
import { bxAnaalyse } from '@/core/icons'

// const res = ["foundation",]
// let redirect = ""
// const PermissionArr = [
//   { key: "foundation", path: "/knowledge_tree/foundation_tree" },
//   { key: "business", path: "/knowledge_tree/service_tree-form" },
//   { key: "association", path: "/knowledge_tree/correlation_of_knowledge_points" },
//   { key: "questionBank", path: "/questionbank" },
//   { key: "dimension", path: "/title_annotation" },
//   { key: "request", path: "/title_request" },
//   { key: "teachingView", path: "/teaching_view" },
//   { key: "teachingEnter", path: "/teaching_assistant/teaching_enter" },
//   { key: "teachingInspect", path: "/teaching_assistant/catalog_Check" },
//   { key: "titleSlicing", path: "/teaching_assistant/title_slicing" },
//   { key: "answerSlicing", path: "/teaching_assistant/answer_slicing" },
//   { key: "titileAssociation", path: "/teaching_assistant/answer_slicing" },
//   { key: "accountManage", path: "/account_permission/account_manage" },
//   { key: "roleManage", path: "/account_permission/role_manage" },
// ]
// if (storage.get("permissionList")) {
//   const permissionList = storage.get("permissionList")
//   for (let item of PermissionArr) {
//     if (permissionList.includes(item.key)) {
//       redirect = item.path
//       break
//     }
//   }
// } else {
//   redirect = "/knowledge_tree/foundation_tree"
// }

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: 'menu.home' },
    redirect: '/knowledge_tree/foundation_tree',
    children: [
      {
        path: '/knowledge_tree',
        redirect: '/knowledge_tree/foundation_tree',
        component: RouteView,
        // meta: { title: '知识树', icon: 'icon-a-fenleizhishishu'},
        meta: { title: '知识树', permission: ['knowledge'], icon: 'knowledge' },
        children: [
          {
            path: '/knowledge_tree/foundation_tree',
            name: 'foundation_tree',
            component: () => import('@/views/knowledge_tree/foundation_tree/foundation_tree'),
            meta: { title: '基础树', permission: ['foundation'], keepAlive: true }
          },
          {
            path: '/knowledge_tree/service_tree-form',
            name: 'service_tree',
            component: () => import('@/views/knowledge_tree/service_tree/service_tree'),
            meta: { title: '业务树', permission: ['business'], keepAlive: true }
          },
          {
            path: '/knowledge_tree/correlation_of_knowledge_points',
            name: 'correlation_of_knowledge_points',
            component: () => import('@/views/knowledge_tree/correlation_of_knowledge_points/correlation_of_knowledge_points'),
            meta: { title: '知识点关联', permission: ['association'], keepAlive: true }
          }
        ]
      },
      {
        path: '/questionbank',
        name: '/questionbank',
        meta: { title: '题库管理', permission: ['questionBank'], icon: 'questionbank' },
        component: () => import('@/views/questionbank/questionbank')
      },
      {
        path: '/title_annotation',
        name: '/title_annotation',
        meta: { title: '题目标注', permission: ['dimension'], icon: 'annotation' },
        component: () => import('@/views/title_annotation/annotation')
      },
      {
        path: '/title_request',
        name: '/title_request',
        meta: { title: '学科网题目请求', permission: ['request'], icon: 'request' },
        component: () => import('@/views/title_request/titleRequest')
      },
      {
        path: '/teaching_view',
        name: '/teaching_view',
        meta: { title: '教辅查看', permission: ['teachingView'], icon: 'teaching_view' },
        component: () => import('@/views/teaching_view/teaching_view')
      },
      {
        path: '/others_teaching_manage',
        name: '/others_teaching_manage',
        meta: { title: '第三方教辅录入管理', permission: ['othersTeachingManage'], icon: 'teaching_enter' },
        component: () => import('@/views/others_teaching_manage/others-teaching-manage')
      },
      {
        path: '/teaching_assistant',
        redirect: '/teaching_assistant/teaching_enter',
        component: RouteView,
        meta: { title: '教辅录入管理', permission: ['teachingManage'], icon: 'teaching_enter' },
        children: [
          {
            path: '/teaching_assistant/teaching_enter',
            name: 'teaching_enter',
            component: () => import('@/views/teaching_assistant/teaching_enter/teaching_enter'),
            meta: { title: '教辅录入', permission: ['teachingEnter'], keepAlive: true }
          },
          {
            path: '/teaching_assistant/catalog_Check',
            name: 'catalog_Check',
            component: () => import('@/views/teaching_assistant/catalog_Check/catalog_Check'),
            meta: { title: '目录检查', permission: ['teachingInspect'], keepAlive: true }
          },
          {
            path: '/teaching_assistant/title_slicing',
            name: 'title_slicing',
            component: () => import('@/views/teaching_assistant/title_slicing/title_slicing'),
            meta: { title: '题目切割', permission: ['titleSlicing'], keepAlive: true }
          },
          {
            path: '/teaching_assistant/answer_slicing',
            name: 'answer_slicing',
            component: () => import('@/views/teaching_assistant/answer_slicing/answer_slicing'),
            meta: { title: '答案切割', permission: ['answerSlicing'], keepAlive: true }
          },
          {
            path: '/teaching_assistant/titile_association',
            name: 'titile_association',
            component: () => import('@/views/teaching_assistant/titile_association/titile_association'),
            meta: { title: '关联识别', permission: ['titileAssociation'], keepAlive: true }
          },
        ]
      },
      {
        path: '/topic_structuring',
        name: '/topic_structuring',
        meta: { title: '题目结构化', permission: ['topicStructuring'], icon: 'topic_structuring' },
        component: () => import('@/views/topic_structuring/topicStructuring')
      },
      {
        path: '/account_permission',
        redirect: '/account_permission/account_manage',
        component: RouteView,
        meta: { title: '账号与权限', permission: ['accountUser'], icon: 'account_user' },
        children: [
          {
            path: '/account_permission/account_manage',
            name: 'account_manage',
            component: () => import('@/views/account_permission/account_manage/account_manage'),
            meta: { title: '账号管理', permission: ['accountManage'], keepAlive: true }
          },
          {
            path: '/account_permission/role_manage',
            name: 'role_manage',
            component: () => import('@/views/account_permission/role_manage/role_manage'),
            meta: { title: '角色权限管理', permission: ['roleManage'], keepAlive: true }
          },
        ]
      },
      {
        path: '/correction_and_testing',
        redirect: '/correction_and_testing/question_correction',
        component: RouteView,
        meta: { title: '批改质检', permission: ['correctionAndTesting'], icon: 'question_correction' },
        children: [
          {
            path: '/correction_and_testing/question_correction',
            name: '/question_correction',
            meta: { title: '题目批改', permission: ['questionCorrection'], icon: '',redDotKey:"exam_correct_red_dot" },
            component: () => import('@/views/question_correction/question-correction'),
          },
          {
            path: '/correction_and_testing/question_quality_testing',
            name: '/question_quality_testing',
            meta: { title: '题目质检', permission: ['questionQualityTesting'], icon: '',redDotKey:"exam_quality_dot" },
            component: () => import('@/views/question_quality_testing/question-quality-testing'),
          },
          {
            path: '/correction_and_testing/question_correction_report_error',
            name: '/question_correction_report_error',
            meta: { title: '批改报错', permission: ['questionCorrectionReportError'], icon: '',redDotKey:"error_queue_red_dot" },
            component: () => import('@/views/question_correction_report_error/question-correction-report-error'),
          },
        ]
      },
      {
        path: '/data_report',
        redirect: '/question_correction_statistics',
        component: RouteView,
        meta: { title: '数据报表', permission: ['dataReport'], icon: 'question_correction' },
        children: [
          {
            path: '/question_correction_statistics',
            name: '/question_correction_statistics',
            meta: { title: '批改统计', permission: ['questionCorrectionStatistics'],},
            component: () => import('@/views/question_correction_statistics/question-correction-statistics'),
          },
          {
            path: '/question_quality_statistics',
            name: '/question_quality_statistics',
            meta: { title: '质检统计', permission: ['questionQualityStatistics'], },
            component: () => import('@/views/question_quality_statistics/question-quality-statistics'),
          },
          {
            path: '/topic_data_statistics',
            name: '/topic_data_statistics',
            meta: { title: '结构化标注统计', permission: ['topicDataStatistics'], },
            component: () => import('@/views/topic_data_statistics/topic-data-statistics'),
          },
        ]
      },
      // {
      //   path: '/question_correction',
      //   name: '/question_correction',
      //   meta: { title: '题目批改', permission: ['questionCorrection'], icon: 'question_correction' },
      //   component: () => import('@/views/question_correction/question-correction'),
      // },
      // {
      //   path: '/question_correction_statistics',
      //   name: '/question_correction_statistics',
      //   meta: { title: '批改统计', permission: ['questionCorrectionStatistics'], icon: 'question_quality_statistics' },
      //   component: () => import('@/views/question_correction_statistics/question-correction-statistics'),
      // },
      // {
      //   path: '/question_correction_report_error',
      //   name: '/question_correction_report_error',
      //   meta: { title: '批改报错', permission: ['questionCorrectionReportError'], icon: 'question_quality-testing' },
      //   component: () => import('@/views/question_correction_report_error/question-correction-report-error'),
      // },
      // {
      //   path: '/question_quality_testing',
      //   name: '/question_quality_testing',
      //   meta: { title: '题目质检', permission: ['questionQualityTesting'], icon: 'question_quality-testing' },
      //   component: () => import('@/views/question_quality_testing/question-quality-testing'),
      // },
      // {
      //   path: '/question_quality_statistics',
      //   name: '/question_quality_statistics',
      //   meta: { title: '质检统计', permission: ['questionQualityStatistics'], icon: 'question_quality_statistics' },
      //   component: () => import('@/views/question_quality_statistics/question-quality-statistics'),
      // },
      // {
      //   path: '/question_correction/operate',
      //   name: 'QuestionCorrectionOperate',
      //   component: () => import('@/views/question_correction/components/question-correction-content'),
      //   meta: { title: '题目批改', keepAlive: false, hideInMenu: true }
      // }
      // list
      // {
      //   path: '/list',
      //   name: 'list',
      //   component: RouteView,
      //   redirect: '/list/table-list',
      //   meta: { title: 'menu.list', icon: 'table', permission: ['table'] },
      //   children: [
      //     {
      //       path: '/list/table-list/:pageNo([1-9]\\d*)?',
      //       name: 'TableListWrapper',
      //       hideChildrenInMenu: true, // 强制显示 MenuItem 而不是 SubMenu
      //       component: () => import('@/views/list/TableList'),
      //       meta: { title: 'menu.list.table-list', keepAlive: true, permission: ['table'] }
      //     },
      //     {
      //       path: '/list/basic-list',
      //       name: 'BasicList',
      //       component: () => import('@/views/list/BasicList'),
      //       meta: { title: 'menu.list.basic-list', keepAlive: true, permission: ['table'] }
      //     },
      //     {
      //       path: '/list/card',
      //       name: 'CardList',
      //       component: () => import('@/views/list/CardList'),
      //       meta: { title: 'menu.list.card-list', keepAlive: true, permission: ['table'] }
      //     },
      //     {
      //       path: '/list/search',
      //       name: 'SearchList',
      //       component: () => import('@/views/list/search/SearchLayout'),
      //       redirect: '/list/search/article',
      //       meta: { title: 'menu.list.search-list', keepAlive: true, permission: ['table'] },
      //       children: [
      //         {
      //           path: '/list/search/article',
      //           name: 'SearchArticles',
      //           component: () => import('../views/list/search/Article'),
      //           meta: { title: 'menu.list.search-list.articles', permission: ['table'] }
      //         },
      //         {
      //           path: '/list/search/project',
      //           name: 'SearchProjects',
      //           component: () => import('../views/list/search/Projects'),
      //           meta: { title: 'menu.list.search-list.projects', permission: ['table'] }
      //         },
      //         {
      //           path: '/list/search/application',
      //           name: 'SearchApplications',
      //           component: () => import('../views/list/search/Applications'),
      //           meta: { title: 'menu.list.search-list.applications', permission: ['table'] }
      //         }
      //       ]
      //     }
      //   ]
      // },

      // // profile
      // {
      //   path: '/profile',
      //   name: 'profile',
      //   component: RouteView,
      //   redirect: '/profile/basic',
      //   meta: { title: 'menu.profile', icon: 'profile', permission: ['profile'] },
      //   children: [
      //     {
      //       path: '/profile/basic',
      //       name: 'ProfileBasic',
      //       component: () => import('@/views/profile/basic'),
      //       meta: { title: 'menu.profile.basic', permission: ['profile'] }
      //     },
      //     {
      //       path: '/profile/advanced',
      //       name: 'ProfileAdvanced',
      //       component: () => import('@/views/profile/advanced/Advanced'),
      //       meta: { title: 'menu.profile.advanced', permission: ['profile'] }
      //     }
      //   ]
      // },

      // // result
      // {
      //   path: '/result',
      //   name: 'result',
      //   component: RouteView,
      //   redirect: '/result/success',
      //   meta: { title: 'menu.result', icon: 'check-circle-o', permission: ['result'] },
      //   children: [
      //     {
      //       path: '/result/success',
      //       name: 'ResultSuccess',
      //       component: () => import(/* webpackChunkName: "result" */ '@/views/result/Success'),
      //       meta: { title: 'menu.result.success', keepAlive: false, hiddenHeaderContent: true, permission: ['result'] }
      //     },
      //     {
      //       path: '/result/fail',
      //       name: 'ResultFail',
      //       component: () => import(/* webpackChunkName: "result" */ '@/views/result/Error'),
      //       meta: { title: 'menu.result.fail', keepAlive: false, hiddenHeaderContent: true, permission: ['result'] }
      //     }
      //   ]
      // },

      // // Exception
      // {
      //   path: '/exception',
      //   name: 'exception',
      //   component: RouteView,
      //   redirect: '/exception/403',
      //   meta: { title: 'menu.exception', icon: 'warning', permission: ['exception'] },
      //   children: [
      //     {
      //       path: '/exception/403',
      //       name: 'Exception403',
      //       component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403'),
      //       meta: { title: 'menu.exception.not-permission', permission: ['exception'] }
      //     },
      //     {
      //       path: '/exception/404',
      //       name: 'Exception404',
      //       component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
      //       meta: { title: 'menu.exception.not-find', permission: ['exception'] }
      //     },
      //     {
      //       path: '/exception/500',
      //       name: 'Exception500',
      //       component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500'),
      //       meta: { title: 'menu.exception.server-error', permission: ['exception'] }
      //     }
      //   ]
      // },

      // // account
      // {
      //   path: '/account',
      //   component: RouteView,
      //   redirect: '/account/center',
      //   name: 'account',
      //   meta: { title: 'menu.account', icon: 'user', keepAlive: true, permission: ['user'] },
      //   children: [
      //     {
      //       path: '/account/center',
      //       name: 'center',
      //       component: () => import('@/views/account/center'),
      //       meta: { title: 'menu.account.center', keepAlive: true, permission: ['user'] }
      //     },
      //     {
      //       path: '/account/settings',
      //       name: 'settings',
      //       component: () => import('@/views/account/settings/Index'),
      //       meta: { title: 'menu.account.settings', hideHeader: true, permission: ['user'] },
      //       redirect: '/account/settings/basic',
      //       hideChildrenInMenu: true,
      //       children: [
      //         {
      //           path: '/account/settings/basic',
      //           name: 'BasicSettings',
      //           component: () => import('@/views/account/settings/BasicSetting'),
      //           meta: { title: 'account.settings.menuMap.basic', hidden: true, permission: ['user'] }
      //         },
      //         {
      //           path: '/account/settings/security',
      //           name: 'SecuritySettings',
      //           component: () => import('@/views/account/settings/Security'),
      //           meta: {
      //             title: 'account.settings.menuMap.security',
      //             hidden: true,
      //             keepAlive: true,
      //             permission: ['user']
      //           }
      //         },
      //         {
      //           path: '/account/settings/custom',
      //           name: 'CustomSettings',
      //           component: () => import('@/views/account/settings/Custom'),
      //           meta: { title: 'account.settings.menuMap.custom', hidden: true, keepAlive: true, permission: ['user'] }
      //         },
      //         {
      //           path: '/account/settings/binding',
      //           name: 'BindingSettings',
      //           component: () => import('@/views/account/settings/Binding'),
      //           meta: { title: 'account.settings.menuMap.binding', hidden: true, keepAlive: true, permission: ['user'] }
      //         },
      //         {
      //           path: '/account/settings/notification',
      //           name: 'NotificationSettings',
      //           component: () => import('@/views/account/settings/Notification'),
      //           meta: {
      //             title: 'account.settings.menuMap.notification',
      //             hidden: true,
      //             keepAlive: true,
      //             permission: ['user']
      //           }
      //         }
      //       ]
      //     }
      //   ]
      // }

      // other
      /*
      {
        path: '/other',
        name: 'otherPage',
        component: PageView,
        meta: { title: '其他组件', icon: 'slack', permission: [ 'dashboard' ] },
        redirect: '/other/icon-selector',
        children: [
          {
            path: '/other/icon-selector',
            name: 'TestIconSelect',
            component: () => import('@/views/other/IconSelectorView'),
            meta: { title: 'IconSelector', icon: 'tool', keepAlive: true, permission: [ 'dashboard' ] }
          },
          {
            path: '/other/list',
            component: RouteView,
            meta: { title: '业务布局', icon: 'layout', permission: [ 'support' ] },
            redirect: '/other/list/tree-list',
            children: [
              {
                path: '/other/list/tree-list',
                name: 'TreeList',
                component: () => import('@/views/other/TreeList'),
                meta: { title: '树目录表格', keepAlive: true }
              },
              {
                path: '/other/list/edit-table',
                name: 'EditList',
                component: () => import('@/views/other/TableInnerEditList'),
                meta: { title: '内联编辑表格', keepAlive: true }
              },
              {
                path: '/other/list/user-list',
                name: 'UserList',
                component: () => import('@/views/other/UserList'),
                meta: { title: '用户列表', keepAlive: true }
              },
              {
                path: '/other/list/role-list',
                name: 'RoleList',
                component: () => import('@/views/other/RoleList'),
                meta: { title: '角色列表', keepAlive: true }
              },
              {
                path: '/other/list/system-role',
                name: 'SystemRole',
                component: () => import('@/views/role/RoleList'),
                meta: { title: '角色列表2', keepAlive: true }
              },
              {
                path: '/other/list/permission-list',
                name: 'PermissionList',
                component: () => import('@/views/other/PermissionList'),
                meta: { title: '权限列表', keepAlive: true }
              }
            ]
          }
        ]
      }
      */
    ]
  },
  // {
  //   path: '/',
  //   name: 'index',
  //   component: BasicLayout,
  //   meta: { title: 'menu.home' },
  //   redirect: '/knowledge_tree/foundation_tree',
  //   children: [
  //     {
  //       path: '/knowledge_tree',
  //       redirect: '/knowledge_tree/foundation_tree',
  //       component: RouteView,
  //       // meta: { title: '知识树', icon: 'icon-a-fenleizhishishu'},
  //       meta: { title: '知识树',  icon: 'knowledge'}, 
  //       children: [
  //         {
  //           path: '/knowledge_tree/foundation_tree',
  //           name: 'foundation_tree',
  //           component: () => import('@/views/knowledge_tree/foundation_tree/foundation_tree'),
  //           meta: { title: '基础树', keepAlive: true}
  //         },
  //         {
  //           path: '/knowledge_tree/service_tree-form',
  //           name: 'service_tree',
  //           component: () => import('@/views/knowledge_tree/service_tree/service_tree'),
  //           meta: { title: '业务树', keepAlive: true}
  //         },
  //         {
  //           path: '/knowledge_tree/correlation_of_knowledge_points',
  //           name: 'correlation_of_knowledge_points',
  //           component: () => import('@/views/knowledge_tree/correlation_of_knowledge_points/correlation_of_knowledge_points'),
  //           meta: { title: '知识点关联', keepAlive: true}
  //         }
  //       ]
  //     },
  //     {
  //       path: '/questionbank',
  //       name: '/questionbank',
  //       meta: { title: '题库管理', icon: 'questionbank' },
  //       component: () => import('@/views/questionbank/questionbank')
  //     },
  //     {
  //       path: '/title_annotation',
  //       name: '/title_annotation',
  //       meta: { title: '题目标注', icon: 'annotation' },
  //       component: () => import('@/views/title_annotation/annotation')
  //     },
  //     {
  //       path: '/title_request',
  //       name: '/title_request',
  //       meta: { title: '学科网题目请求', icon: 'request' },
  //       component: () => import('@/views/title_request/titleRequest')
  //     },
  //     {
  //       path: '/teaching_view',
  //       name: '/teaching_view',
  //       meta: { title: '教辅查看', icon: 'teaching_view' },
  //       component: () => import('@/views/teaching_view/teaching_view')
  //     },
  //     {
  //       path: '/teaching_assistant',
  //       redirect: '/teaching_assistant/teaching_enter',
  //       component: RouteView,
  //       meta: { title: '教辅录入管理', icon: 'teaching_enter'},
  //       children: [
  //         {
  //           path: '/teaching_assistant/teaching_enter',
  //           name: 'teaching_enter',
  //           component: () => import('@/views/teaching_assistant/teaching_enter/teaching_enter'),
  //           meta: { title: '教辅录入', keepAlive: true}
  //         },
  //         {
  //           path: '/teaching_assistant/catalog_Check',
  //           name: 'catalog_Check',
  //           component: () => import('@/views/teaching_assistant/catalog_Check/catalog_Check'),
  //           meta: { title: '目录检查', keepAlive: true}
  //         },
  //         {
  //           path: '/teaching_assistant/title_slicing',
  //           name: 'title_slicing',
  //           component: () => import('@/views/teaching_assistant/title_slicing/title_slicing'),
  //           meta: { title: '题目切割',keepAlive: true}
  //         },
  //         {
  //           path: '/teaching_assistant/answer_slicing',
  //           name: 'answer_slicing',
  //           component: () => import('@/views/teaching_assistant/answer_slicing/answer_slicing'),
  //           meta: { title: '答案切割', keepAlive: true}
  //         },
  //         {
  //           path: '/teaching_assistant/titile_association',
  //           name: 'titile_association',
  //           component: () => import('@/views/teaching_assistant/titile_association/titile_association'),
  //           meta: { title: '关联识别', keepAlive: true}
  //         },
  //       ]
  //     },
  //     {
  //       path: '/account_permission',
  //       redirect: '/account_permission/account_manage',
  //       component: RouteView,
  //       meta: { title: '账号与权限', icon: 'account_user'},
  //       children: [
  //         {
  //           path: '/account_permission/account_manage',
  //           name: 'account_manage',
  //           component: () => import('@/views/account_permission/account_manage/account_manage'),
  //           meta: { title: '账号管理', keepAlive: true}
  //         },
  //         {
  //           path: '/account_permission/role_manage',
  //           name: 'role_manage',
  //           component: () => import('@/views/account_permission/role_manage/role_manage'),
  //           meta: { title: '角色权限管理', keepAlive: true}
  //         },
  //       ]
  //     },
  //     // list
  //     // {
  //     //   path: '/list',
  //     //   name: 'list',
  //     //   component: RouteView,
  //     //   redirect: '/list/table-list',
  //     //   meta: { title: 'menu.list', icon: 'table', permission: ['table'] },
  //     //   children: [
  //     //     {
  //     //       path: '/list/table-list/:pageNo([1-9]\\d*)?',
  //     //       name: 'TableListWrapper',
  //     //       hideChildrenInMenu: true, // 强制显示 MenuItem 而不是 SubMenu
  //     //       component: () => import('@/views/list/TableList'),
  //     //       meta: { title: 'menu.list.table-list', keepAlive: true, permission: ['table'] }
  //     //     },
  //     //     {
  //     //       path: '/list/basic-list',
  //     //       name: 'BasicList',
  //     //       component: () => import('@/views/list/BasicList'),
  //     //       meta: { title: 'menu.list.basic-list', keepAlive: true, permission: ['table'] }
  //     //     },
  //     //     {
  //     //       path: '/list/card',
  //     //       name: 'CardList',
  //     //       component: () => import('@/views/list/CardList'),
  //     //       meta: { title: 'menu.list.card-list', keepAlive: true, permission: ['table'] }
  //     //     },
  //     //     {
  //     //       path: '/list/search',
  //     //       name: 'SearchList',
  //     //       component: () => import('@/views/list/search/SearchLayout'),
  //     //       redirect: '/list/search/article',
  //     //       meta: { title: 'menu.list.search-list', keepAlive: true, permission: ['table'] },
  //     //       children: [
  //     //         {
  //     //           path: '/list/search/article',
  //     //           name: 'SearchArticles',
  //     //           component: () => import('../views/list/search/Article'),
  //     //           meta: { title: 'menu.list.search-list.articles', permission: ['table'] }
  //     //         },
  //     //         {
  //     //           path: '/list/search/project',
  //     //           name: 'SearchProjects',
  //     //           component: () => import('../views/list/search/Projects'),
  //     //           meta: { title: 'menu.list.search-list.projects', permission: ['table'] }
  //     //         },
  //     //         {
  //     //           path: '/list/search/application',
  //     //           name: 'SearchApplications',
  //     //           component: () => import('../views/list/search/Applications'),
  //     //           meta: { title: 'menu.list.search-list.applications', permission: ['table'] }
  //     //         }
  //     //       ]
  //     //     }
  //     //   ]
  //     // },

  //     // // profile
  //     // {
  //     //   path: '/profile',
  //     //   name: 'profile',
  //     //   component: RouteView,
  //     //   redirect: '/profile/basic',
  //     //   meta: { title: 'menu.profile', icon: 'profile', permission: ['profile'] },
  //     //   children: [
  //     //     {
  //     //       path: '/profile/basic',
  //     //       name: 'ProfileBasic',
  //     //       component: () => import('@/views/profile/basic'),
  //     //       meta: { title: 'menu.profile.basic', permission: ['profile'] }
  //     //     },
  //     //     {
  //     //       path: '/profile/advanced',
  //     //       name: 'ProfileAdvanced',
  //     //       component: () => import('@/views/profile/advanced/Advanced'),
  //     //       meta: { title: 'menu.profile.advanced', permission: ['profile'] }
  //     //     }
  //     //   ]
  //     // },

  //     // // result
  //     // {
  //     //   path: '/result',
  //     //   name: 'result',
  //     //   component: RouteView,
  //     //   redirect: '/result/success',
  //     //   meta: { title: 'menu.result', icon: 'check-circle-o', permission: ['result'] },
  //     //   children: [
  //     //     {
  //     //       path: '/result/success',
  //     //       name: 'ResultSuccess',
  //     //       component: () => import(/* webpackChunkName: "result" */ '@/views/result/Success'),
  //     //       meta: { title: 'menu.result.success', keepAlive: false, hiddenHeaderContent: true, permission: ['result'] }
  //     //     },
  //     //     {
  //     //       path: '/result/fail',
  //     //       name: 'ResultFail',
  //     //       component: () => import(/* webpackChunkName: "result" */ '@/views/result/Error'),
  //     //       meta: { title: 'menu.result.fail', keepAlive: false, hiddenHeaderContent: true, permission: ['result'] }
  //     //     }
  //     //   ]
  //     // },

  //     // // Exception
  //     // {
  //     //   path: '/exception',
  //     //   name: 'exception',
  //     //   component: RouteView,
  //     //   redirect: '/exception/403',
  //     //   meta: { title: 'menu.exception', icon: 'warning', permission: ['exception'] },
  //     //   children: [
  //     //     {
  //     //       path: '/exception/403',
  //     //       name: 'Exception403',
  //     //       component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403'),
  //     //       meta: { title: 'menu.exception.not-permission', permission: ['exception'] }
  //     //     },
  //     //     {
  //     //       path: '/exception/404',
  //     //       name: 'Exception404',
  //     //       component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
  //     //       meta: { title: 'menu.exception.not-find', permission: ['exception'] }
  //     //     },
  //     //     {
  //     //       path: '/exception/500',
  //     //       name: 'Exception500',
  //     //       component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500'),
  //     //       meta: { title: 'menu.exception.server-error', permission: ['exception'] }
  //     //     }
  //     //   ]
  //     // },

  //     // // account
  //     // {
  //     //   path: '/account',
  //     //   component: RouteView,
  //     //   redirect: '/account/center',
  //     //   name: 'account',
  //     //   meta: { title: 'menu.account', icon: 'user', keepAlive: true, permission: ['user'] },
  //     //   children: [
  //     //     {
  //     //       path: '/account/center',
  //     //       name: 'center',
  //     //       component: () => import('@/views/account/center'),
  //     //       meta: { title: 'menu.account.center', keepAlive: true, permission: ['user'] }
  //     //     },
  //     //     {
  //     //       path: '/account/settings',
  //     //       name: 'settings',
  //     //       component: () => import('@/views/account/settings/Index'),
  //     //       meta: { title: 'menu.account.settings', hideHeader: true, permission: ['user'] },
  //     //       redirect: '/account/settings/basic',
  //     //       hideChildrenInMenu: true,
  //     //       children: [
  //     //         {
  //     //           path: '/account/settings/basic',
  //     //           name: 'BasicSettings',
  //     //           component: () => import('@/views/account/settings/BasicSetting'),
  //     //           meta: { title: 'account.settings.menuMap.basic', hidden: true, permission: ['user'] }
  //     //         },
  //     //         {
  //     //           path: '/account/settings/security',
  //     //           name: 'SecuritySettings',
  //     //           component: () => import('@/views/account/settings/Security'),
  //     //           meta: {
  //     //             title: 'account.settings.menuMap.security',
  //     //             hidden: true,
  //     //             keepAlive: true,
  //     //             permission: ['user']
  //     //           }
  //     //         },
  //     //         {
  //     //           path: '/account/settings/custom',
  //     //           name: 'CustomSettings',
  //     //           component: () => import('@/views/account/settings/Custom'),
  //     //           meta: { title: 'account.settings.menuMap.custom', hidden: true, keepAlive: true, permission: ['user'] }
  //     //         },
  //     //         {
  //     //           path: '/account/settings/binding',
  //     //           name: 'BindingSettings',
  //     //           component: () => import('@/views/account/settings/Binding'),
  //     //           meta: { title: 'account.settings.menuMap.binding', hidden: true, keepAlive: true, permission: ['user'] }
  //     //         },
  //     //         {
  //     //           path: '/account/settings/notification',
  //     //           name: 'NotificationSettings',
  //     //           component: () => import('@/views/account/settings/Notification'),
  //     //           meta: {
  //     //             title: 'account.settings.menuMap.notification',
  //     //             hidden: true,
  //     //             keepAlive: true,
  //     //             permission: ['user']
  //     //           }
  //     //         }
  //     //       ]
  //     //     }
  //     //   ]
  //     // }

  //     // other
  //     /*
  //     {
  //       path: '/other',
  //       name: 'otherPage',
  //       component: PageView,
  //       meta: { title: '其他组件', icon: 'slack', permission: [ 'dashboard' ] },
  //       redirect: '/other/icon-selector',
  //       children: [
  //         {
  //           path: '/other/icon-selector',
  //           name: 'TestIconSelect',
  //           component: () => import('@/views/other/IconSelectorView'),
  //           meta: { title: 'IconSelector', icon: 'tool', keepAlive: true, permission: [ 'dashboard' ] }
  //         },
  //         {
  //           path: '/other/list',
  //           component: RouteView,
  //           meta: { title: '业务布局', icon: 'layout', permission: [ 'support' ] },
  //           redirect: '/other/list/tree-list',
  //           children: [
  //             {
  //               path: '/other/list/tree-list',
  //               name: 'TreeList',
  //               component: () => import('@/views/other/TreeList'),
  //               meta: { title: '树目录表格', keepAlive: true }
  //             },
  //             {
  //               path: '/other/list/edit-table',
  //               name: 'EditList',
  //               component: () => import('@/views/other/TableInnerEditList'),
  //               meta: { title: '内联编辑表格', keepAlive: true }
  //             },
  //             {
  //               path: '/other/list/user-list',
  //               name: 'UserList',
  //               component: () => import('@/views/other/UserList'),
  //               meta: { title: '用户列表', keepAlive: true }
  //             },
  //             {
  //               path: '/other/list/role-list',
  //               name: 'RoleList',
  //               component: () => import('@/views/other/RoleList'),
  //               meta: { title: '角色列表', keepAlive: true }
  //             },
  //             {
  //               path: '/other/list/system-role',
  //               name: 'SystemRole',
  //               component: () => import('@/views/role/RoleList'),
  //               meta: { title: '角色列表2', keepAlive: true }
  //             },
  //             {
  //               path: '/other/list/permission-list',
  //               name: 'PermissionList',
  //               component: () => import('@/views/other/PermissionList'),
  //               meta: { title: '权限列表', keepAlive: true }
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //     */
  //   ]
  // },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
